import React from "react";
import { Grid, Button } from "@mui/material";
import {
  Instagram,
  WhatsApp,
  LinkedIn,
  FacebookOutlined,
  X,
  YouTube,
} from "@mui/icons-material/";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import LazyImage from "./LazyImage";

const Footer = ({ phonewidth }) => {
  /**
   * Function to navigate between routes.
   * @type {Function}
   */
  const navigate = useNavigate();

  // Enlaces a páginas internas
  const pages = [
    { name: "Mapa de sitio", url: "/mapa-de-sitio" },
    { name: "Términos y condiciones", url: "/terminos-y-condiciones" },
    { name: "Calidad y seguridad", url: "/calidad-y-seguridad" },
    { name: "Aviso de privacidad", url: "/aviso-de-privacidad" },
    { name: "Bolsa de trabajo", url: "/solicitud-empleo" },
  ];

  /**
   * Renders a button for a given page, tracking clicks with Google Analytics and navigating to the specified URL.
   *
   * @component
   * @param {Object} props - The properties object.
   * @param {Object} props.page - The page object containing information about the page.
   * @param {string} props.page.name - The name of the page, used for the button label and analytics tracking.
   * @param {string} props.page.url - The URL to navigate to when the button is clicked.
   *
   * @returns {JSX.Element} The rendered button component.
   */
  const PagesButton = ({ page }) => (
    <Button
      key={page.name}
      name={page.name}
      title={page.name}
      variant="text"
      size="medium"
      style={design.txt}
      onClick={() => {
        ReactGA.event({
          category: "Interaccion",
          action: `Visit a ${page.name} - footer`,
          label: page.name,
          value: 1,
        });
        navigate(page.url);
      }}
    >
      {page.name}
    </Button>
  );

  // Enlaces a plataformas sociales
  const sociales = [
    {
      icon: <Instagram fontSize="medium" style={design.icon} />,
      name: "Instagram",
      url: "https://www.instagram.com/movicare_mx/?hl=es-la",
    },
    {
      icon: <WhatsApp fontSize="medium" style={design.icon} />,
      name: "WhatsApp",
      url: "https://api.whatsapp.com/send?phone=525514772163&text=Hola%2C%20requiero%20informes%2C%20gracias&fbclid=IwAR2u7j0586-sC7_E9DC-zU9lxIjdIcnOkEfGqgfKQLl8iIdxQf0Y9lSh6zg",
    },
    {
      icon: <X fontSize="medium" style={design.icon} />,
      name: "X",
      url: "https://twitter.com/MoviCareMx",
    },
    {
      icon: <FacebookOutlined fontSize="medium" style={design.icon} />,
      name: "Facebook",
      url: "https://www.facebook.com/MoviCareMx/",
    },
    {
      icon: <LinkedIn fontSize="medium" style={design.icon} />,
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/movicaremx/",
    },
    {
      icon: <YouTube fontSize="medium" style={design.icon} />,
      name: "YouTube",
      url: "https://www.youtube.com/@movicareserviciosmedicos6272",
    },
  ];

  /**
   * A button component for social media links.
   * Renders a button with the social media icon and tracks clicks using Google Analytics.
   * @param {Object} props - The component props.
   * @param {Object} props.social - The social media object.
   * @param {string} props.social.name - The name of the social media platform.
   * @param {string} props.social.url - The URL to the social media platform.
   * @param {JSX.Element} props.social.icon - The icon representing the social media platform.
   * @returns {JSX.Element} A button that links to the specified social media platform.
   * @function
   */
  const SocialButton = ({ social }) => (
    <Button
      key={social.name}
      name={social.name}
      title={social.name}
      variant="text"
      rel="nofollow"
      target="_blank"
      size="small"
      href={social.url}
      onClick={() => {
        ReactGA.event({
          category: "Interaccion",
          action: `Visit a ${social.name} - footer`,
          label: social.name,
          value: 1,
        });
      }}
    >
      {social.icon}
    </Button>
  );

  return (
    <>
      <Grid
        container
        style={design.root}
      >
        {phonewidth ? (
          <>
            <Grid item xs={12} md={12} xl={12}>
              <a rel="noopener noreferrer" href="/">
              <LazyImage
                  alt="Movicare_logo"
                  src="https://movicaremx.com/IMG/BACKGROUNDS/MOVICARE_3000Color.png"
                  w={phonewidth ? "75%" : '30%'}
                  h={phonewidth ? "9%" : '25%'}
                  //1600x413
                  variant="rectangular"
                  style={{ marginLeft: "12.5%" }}
                  title="Movicare_logo"
                  key="Movicare_logo"
                />

                {/* <LazyImage
                  alt="Movicare_logo"
                  src="https://movicaremx.com/IMG/Sitioweb/Logo_MoviCare_Navidad_2024.png"
                  w={200}
                  h={96}
                  // 810 × 246
                  style={{ marginLeft: "25%" }}
                  title="Movicare_logo"
                  key="Movicare_logo"
                /> */}
              </a>
              <p style={{ ...design.cap, margin: "0% 0% 1% 3%" }}>
                Acelerado por &nbsp;
                <LazyImage
                  alt="AvantLab_logo"
                  src="https://movicaremx.com/IMG/Sitioweb/avantLab_logo_azul.png"
                  w={75}
                  h={33}
                  // 754 x 331
                  style={{ marginBottom: "-2%" }}
                  title="AvantLab_logo"
                  key="AvantLab_logo"
                />
              </p>
              <div style={{ textAlign: "center" }}>
                {sociales.map((social, index) => (
                  <SocialButton
                    key={`${social.name}-${index}-footer`}
                    social={social}
                  />
                ))}{" "}
                <br />
                <Button
                  name="atencionaclientes@movicaremx.com"
                  title="atencionaclientes@movicaremx.com"
                  variant="text"
                  size="medium"
                  rel="nofollow"
                  style={design.txt}
                  target="_blank"
                  href="mailto:atencionaclientes@movicaremx.com?Subject=Hola%20tengo%20una%20duda"
                  onClick={() => {
                    ReactGA.event({
                      category: "Interaccion",
                      action: "atencionaclientes@movicaremx.com-footer",
                      label: "atencionaclientes@movicaremx.com",
                      value: 1,
                    });
                  }}
                >
                  atencionaclientes@movicaremx.com
                </Button>
                <br />
                <Button
                  name="5514772163"
                  title="5514772163"
                  variant="text"
                  rel="nofollow"
                  size="medium"
                  style={design.txt}
                  target="_blank"
                  // ¡Hola MoviCare! 🙌🏼 Podrían brindarme mayor información por favor ☺🧪
                  href="https://wa.link/9fsyx4"
                  onClick={() => {
                    ReactGA.event({
                      category: "Interaccion",
                      action: "5514772163-footer",
                      label: "55 1477 21 63",
                      value: 1,
                    });
                  }}
                >
                  55 1477 21 63
                </Button>
                {pages.map((page, index) => (
                  <>
                    <div key={`${page.name}-${index}-footer`}>
                      <PagesButton
                        key={`${page.name}-${index}-footer`}
                        page={page}
                      />
                    </div>
                  </>
                ))}
              </div>
              <p style={design.cap}>
                Servicios médicos subrogados <br /> Aviso de funcionamiento
                COFEPRIS: 2415195036X00033
                <br />
                <LazyImage
                  alt="COFEPRIS"
                  src="https://movicaremx.com/IMG/Sitioweb/cofepris.png"
                  w={106}
                  h={25.5}
                  variant="rectangular"
                  // 426 x 102
                  title="COFEPRIS"
                  key="COFEPRIS"
                />
                <LazyImage
                  alt="COPRISEM"
                  src="https://movicaremx.com/IMG/Sitioweb/Coprisem.png"
                  w={87}
                  h={31}
                  variant="rectangular"
                  style={{ marginLeft: "5%" }}
                  // 348 x 124
                  title="COPRISEM"
                  key="COPRISEM"
                />
              </p>
              <p style={design.cap}>
                Movicare Servicios Integrales S.A.P.I. de C.V.
              </p>
              <p style={design.cap}>
                Copyright ® Todos los derechos reservados.
              </p>
              <p style={design.cap}>
                Consulte a su médico, quien es la única persona capacitada para
                decidir qué estudios de laboratorio y radiografías son
                apropiados para cada paciente, en función de su historial
                clínico e interpretando los resultados.
              </p>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={3} xl={3} style={design.gridlat}>
              {sociales.map((social, index) => (
                <SocialButton
                  key={`${social.name}-${index}-footer`}
                  social={social}
                />
              ))}
              <br />
              <Button
                name="atencionaclientes@movicaremx.com"
                title="atencionaclientes@movicaremx.com"
                variant="text"
                size="medium"
                rel="nofollow"
                style={design.txt}
                target="_blank"
                href="mailto:atencionaclientes@movicaremx.com?Subject=Hola%20tengo%20una%20duda"
                onClick={() => {
                  ReactGA.event({
                    category: "Interaccion",
                    action:
                      "Envio de correo electrónico - atencionaclientes@movicaremx.com - footer",
                    label: "atencionaclientes@movicaremx.com",
                    value: 1,
                  });
                }}
              >
                atencionaclientes@movicaremx.com
              </Button>
              <br />
              <Button
                name="5514772163"
                title="5514772163"
                variant="text"
                rel="nofollow"
                size="medium"
                style={design.txt}
                target="_blank"
                // ¡Hola MoviCare! 🙌🏼 Podrían brindarme mayor información por favor ☺🧪
                href="https://wa.link/9fsyx4"
                onClick={() => {
                  ReactGA.event({
                    category: "Interaccion",
                    action: "Envío de WhatsApp 5514772163 - footer",
                    label: "5514772163",
                    value: 1,
                  });
                }}
              >
                55 1477 21 63
              </Button>
              <br />
              <Button
                name="Preguntas frecuentes"
                title="Preguntas frecuentes"
                variant="text"
                size="large"
                style={design.txt}
                onClick={() => {
                  ReactGA.event({
                    category: "Interaccion",
                    action: "Preguntas-frecuentes-footer",
                    label: "Preguntas-frecuentes",
                    value: 1,
                  });
                  navigate("/preguntas-frecuentes");
                }}
              >
                Preguntas frecuentes
              </Button>
              <br />
              <Button
                name="Preguntas frecuentes"
                title="Preguntas frecuentes"
                variant="text"
                size="large"
                style={design.txt}
                onClick={() => {
                  // Event GA4
                  ReactGA.event({
                    category: "Interaccion",
                    action: "Acelerado-por-AvantLab-footer",
                    label: "Acelerado por AvantLab",
                    value: 1,
                  });
                  navigate("/");
                }}
              >
                Acelerado por &nbsp;
                <LazyImage
                  alt="AvantLab_logo"
                  src="https://movicaremx.com/IMG/Sitioweb/avantLab_logo_azul.png"
                  w={75}
                  h={33}
                  variant="rectangular"
                  // 754 x 331
                  title="AvantLab_logo"
                  key="AvantLab_logo"
                />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={5}
              justifyContent="center"
              alignItems="center"
            >
              <a rel="noopener noreferrer" href="/">
                <LazyImage
                  alt="Movicare_logo"
                  src="https://movicaremx.com/IMG/BACKGROUNDS/MOVICARE_3000Color.png"
                  w={phonewidth ? "75%" : '30%'}
                  h={phonewidth ? "9%" : '25%'}
                  //1600x413
                  variant="rectangular"
                  style={{ marginLeft: "35%" }}
                  title="Movicare_logo"
                  key="Movicare_logo"
                />
              </a>
              <p style={design.cap}>
                Servicios médicos subrogados <br /> Aviso de funcionamiento
                COFEPRIS: 2415195036X00033
                <br />
                <LazyImage
                  alt="COFEPRIS"
                  src="https://movicaremx.com/IMG/Sitioweb/cofepris.png"
                  w={106}
                  h={25.5}
                  variant="rectangular"
                  // 426 x 102
                  title="COFEPRIS"
                  key="COFEPRIS"
                />
                <LazyImage
                  alt="COPRISEM"
                  src="https://movicaremx.com/IMG/Sitioweb/Coprisem.png"
                  w={87}
                  h={31}
                  variant="rectangular"
                  style={{ marginLeft: "5%" }}
                  // 348 x 124
                  title="COPRISEM"
                  key="COPRISEM"
                />
              </p>
              <p style={design.cap}>
                Movicare Servicios Integrales S.A.P.I. de C.V.
              </p>
              <p style={design.cap}>
                Copyright ® Todos los derechos reservados.
              </p>
            </Grid>
            <Grid item xs={12} md={3} xl={3} style={design.gridlat}>
              {pages.map((page, index) => (
                <>
                  <div key={`${page.name}-${index}-footer`}>
                    <PagesButton
                      key={`${page.name}-${index}-footer`}
                      page={page}
                    />
                  </div>
                </>
              ))}
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <p style={design.cap}>
                Consulte a su médico, quien es la única persona capacitada para
                decidir qué estudios de laboratorio y radiografías son
                apropiados para cada paciente, en función de su historial
                clínico e interpretando los resultados.
              </p>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Footer;

/**
 * design es un objeto que contiene estilos personalizados para el texto dentro del tooltip.
 * @type {Object}
 */
const design = {
  root: {
    elevation: 3,
    background: "#FEFCFA",
  },
  icon: {
    color: "#00528CA6",
    fontWeight: "bold",
    margin: "5% 0%",
  },
  txt: {
    fontFamily: "Gabarito",
    color: "#00528CA6",
    padding: "0.5% 4%",
    marginBottom: "5px",
    textDecoration: "none",
    textTransform: "none",
    "&:hover": {
      textDecorationLine: "underline",
      textDecorationColor: "rgba(68, 150, 210, 0.55)",
      backgroundColor: "rgba(68, 150, 210, 0.55)",
      textDecorationStyle: "solid",
    },
  },
  cap: {
    fontFamily: "Gabarito",
    color: "#00528CA6",
    textAlign: "center",
    textTransform: "none",
  },
  gridlat: {
    textAlign: "left",
    margin: "0% 2%",
  },
};
